import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../../layout/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero/background-color"
import CTA from "../../components/cta"
import PageWrapper from "../../components/page-wrapper"
import CardVertical from "../../components/card-vertical"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import content from "../../locales/en/products-overview.json"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "120px",
  },
  header: {
    width: "70%",
    marginTop: "36px",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  gridContainer: {
    marginTop: "48px",
    marginBottom: "48px",
    "@media (max-width:768px)": {
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
})

export default function ProductsOverview({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    query ProductsOverviewContent {
      heroImage: file(relativePath: { eq: "home/index_hero.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      serogelImageCloseUp: file(
        relativePath: { eq: "serogel/serogel_closeup_1.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      derogelImageCloseUp: file(
        relativePath: { eq: "derogel/derogel_fibres_closeup_1.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const heroImage = data.heroImage.childImageSharp.fluid
  const subsectionImgLeft = data.serogelImageCloseUp.childImageSharp.fluid
  const subsectionImgRight = data.derogelImageCloseUp.childImageSharp.fluid

  content.section1.cards[0].imageData = subsectionImgLeft
  content.section1.cards[1].imageData = subsectionImgRight

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    new RegExp("-", "g"),
    " "
  )
  const classes = useStyles()

  return (
    <>
      <Layout location={location}>
      <SEO title={content.seo.title} />
        <Hero
          textTitle={content.hero.heading}
          textDescription={content.hero.description}
        />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />
            <div className={classes.headingContainer}>
              <Typography className={classes.header} variant="h2">
                {content.section1.heading}
              </Typography>
            </div>
            <Grid
              className={classes.gridContainer}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {content.section1.cards.map((card, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  <Link style={{ textDecoration: "none" }} to={card.linkUrl}>
                    <CardVertical
                      title={card.heading}
                      description={card.description}
                      imageData={card.imageData}
                      imgAlt={card.imgAlt}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        </PageWrapper>
        <CTA imageData={subsectionImgLeft} />
      </Layout>
    </>
  )
}
